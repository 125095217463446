import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "instructors" }
const _hoisted_2 = { class: "instructors__block" }
const _hoisted_3 = { class: "main-table__mobile-wrap" }
const _hoisted_4 = { class: "main-table__head" }
const _hoisted_5 = { class: "main-table__pluse" }
const _hoisted_6 = {
  key: 0,
  class: "main-table__head main-table__head-bottom"
}
const _hoisted_7 = { class: "main-table__body-id" }
const _hoisted_8 = { class: "main-table__body-big-tr" }
const _hoisted_9 = { class: "main-table__billing-activity-second" }
const _hoisted_10 = { class: "main-table__billing-activity-third" }
const _hoisted_11 = { class: "main-table__billing-activity-fourth" }
const _hoisted_12 = {
  key: 1,
  class: "main-table__body-big-tr"
}
const _hoisted_13 = { class: "main-table__body-big-tr" }
const _hoisted_14 = { class: "main-table__body-big-tr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_Pluse = _resolveComponent("Pluse")!
  const _component_Minuse = _resolveComponent("Minuse")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "main-table__head-id" }, " ID ", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "main-table__head-big-tr" }, " Lead time ", -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["main-table__head-big-tr", {
            'main-table__billing-activity main-table__billing-activity-center':
              _ctx.isShowBilling
          }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togleBilling()))
        }, [
          _cache[1] || (_cache[1] = _createTextVNode(" Billable students ")),
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.isShowBilling)
              ? (_openBlock(), _createBlock(_component_Pluse, { key: 0 }))
              : (_openBlock(), _createBlock(_component_Minuse, { key: 1 }))
          ])
        ], 2),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "main-table__head-big-tr" }, " Price per student ", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "main-table__head-big-tr" }, " Amount payable ", -1))
      ]),
      (_ctx.isShowBilling)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "main-table__head-id" }, null, -1)),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "main-table__head-big-tr" }, null, -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["main-table__head-big-tr", { 'main-table__billing-activity': _ctx.isShowBilling }])
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("div", null, "On the first day of month", -1),
              _createElementVNode("div", null, "New or reactivated", -1),
              _createElementVNode("div", null, "Deactivated", -1),
              _createElementVNode("div", null, "On the last day of month", -1),
              _createElementVNode("div", null, "Billable", -1)
            ]), 2),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "main-table__head-big-tr" }, null, -1)),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "main-table__head-big-tr" }, null, -1))
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.instructorsData, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "main-table__body",
          key: item
        }, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(item.id), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(item.lead_time), 1),
          (_ctx.isShowBilling)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["main-table__body-big-tr", { 'main-table__billing-activity': _ctx.isShowBilling }])
              }, [
                _createElementVNode("div", null, _toDisplayString(item.on_first_day_of_month), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(item.on_first_day_of_month), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(item.deactivated), 1),
                _createElementVNode("div", _hoisted_11, _toDisplayString(item.on_last_day_of_month), 1),
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "main-table__billing-collum" }, "Billable", -1))
              ], 2))
            : _createCommentVNode("", true),
          (!_ctx.isShowBilling)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(item.billable_students), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, _toDisplayString(item?.price_per_student), 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(item.amount_payable), 1)
        ]))
      }), 128))
    ]),
    _createVNode(_component_Pagination, {
      propertyMeta: _ctx.instructorsMeta,
      propertyList: _ctx.instructorsData,
      page: _ctx.page,
      handlePage: _ctx.handlePage
    }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"])
  ]))
}