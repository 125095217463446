
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import Pluse from "@/assets/svg/pluse-blue.svg?inline";
import Minuse from "@/assets/svg/minuse-blue.svg?inline";

export default defineComponent({
  name: "StudentsHistory",
  data() {
    return {
      page: 1,
      instructorsData: {},
      instructorsMeta: null,
      errorInstructors: null,
      sortData: "",
      isShowBilling: false,
    };
  },
  components: {
    AdminPanelNavMenu,
    SortArrow,
    AlertBlock,
    Pagination,
    Pluse,
    Minuse,
  },
  watch: {
    searchData() {
      this.getBillingLog();
      this.page = 1;
    },
  },
  mounted() {
    this.getBillingLog();
  },
  methods: {
    getBillingLog(params) {
      this.$store.dispatch("analytics/getBillingActivity", params).then(
        (res) => {
          this.instructorsData = res.data;
          this.instructorsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getBillingLog();
    },
    togleBilling() {
      this.isShowBilling = !this.isShowBilling;
    },
  },
});
